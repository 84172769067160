import Button from './Button';
import Col from './Grid/Col';
import Row from './Grid/Row';
import iconAttachmentX from '@/assets/img/icons/icon_attachment_x@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { usePopupStore } from '@/stores/PopupStore';
import { observer } from 'mobx-react-lite';
import React, {
  ChangeEventHandler,
  FC,
  InputHTMLAttributes,
  useCallback,
} from 'react';
import styled from 'styled-components';

const AttachmentContainer = styled(Row)`
  margin: 0 -6px;
  align-items: flex-start;

  .col-left,
  .col-right {
    padding: 0 6px;
  }

  .col-left {
    width: 164px;
  }
  .col-right {
    width: calc(100% - 164px);
  }

  ${Button} {
    width: 100%;
    font-size: 16px;
    z-index: -1;
  }
  /* 우선 후원완료 페이지 기준으로 퍼블리싱 */
  ${breakpoint(`mobile`)} {
    .col-left {
      width: 96px;
    }
    .col-right {
      width: calc(100% - 96px);
    }

    ${Button} {
      font-size: 14px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
`;

const InputFile = styled.input`
  opacity: 0;
  height: 100%;
  font-size: 0;
  position: absolute;
  left: 0;
  top: 0;
`;
const Label = styled.label`
  display: block;
  position: relative;
  z-index: 1;
`;
const FileCol = styled(Col)`
  overflow: hidden;
`;
const FilePreview = styled.div`
  height: 52px;
  line-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 16px;
  color: #828385;
  ${breakpoint(640)} {
    height: 46px;
    line-height: 46px;
  }
`;

interface AttachmentProps extends InputHTMLAttributes<HTMLInputElement> {
  maxFileCount?: number;
  files: File[];
  onFilesChange: (files: File[]) => void;
}

const Attachment: FC<AttachmentProps> = observer(
  ({ maxFileCount = 5, files, onFilesChange, ...props }) => {
    const popupStore = usePopupStore();

    // 첨부파일 지정
    const handleAttachmentFile: ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        // null 인 경우
        if (!event.target.files || !event.target.files.length) {
          return;
        }

        const newFiles = [...files, ...Array.from(event.target.files)];
        // 최대 갯수를 초과하면
        if (newFiles.length > maxFileCount) {
          popupStore.show(`최대 ${maxFileCount}개 까지 업로드 가능합니다`);
        }

        const finalFiles = newFiles.slice(0, maxFileCount);

        onFilesChange(finalFiles);

        // 파일 input 초기화
        event.target.value = null;
      },
      [files, maxFileCount, onFilesChange, popupStore],
    );

    const removeFile = (file: File) =>
      onFilesChange(files.filter((f) => f !== file));

    return (
      <AttachmentContainer>
        <div className="col-left">
          <Label>
            <InputFile type="file" onChange={handleAttachmentFile} {...props} />
            <Button type="button" full color="white-grey">
              파일첨부
            </Button>
          </Label>
        </div>
        <div className="col-right">
          {files.length > 0 ? (
            files.map((file) => (
              <Row key={file.name}>
                <FileCol>
                  <FilePreview>{file.name}</FilePreview>
                </FileCol>
                <Col desktop="none">
                  <button type="button" onClick={() => removeFile(file)}>
                    <img
                      src={iconAttachmentX}
                      alt="X"
                      width="28"
                      height="28"
                      css={`
                        cursor: pointer;
                        margin-right: 8px;
                      `}
                    />
                  </button>
                </Col>
              </Row>
            ))
          ) : (
            <Row>
              <FileCol>
                <FilePreview>선택된 파일없음</FilePreview>
              </FileCol>
            </Row>
          )}
        </div>
      </AttachmentContainer>
    );
  },
);

export default Attachment;
