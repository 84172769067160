/**
 * 보드 종류 (공지사항)
 */
export const BOARD_KIND_NOTICE = `100`;

/**
 * 보드 종류 (보도자료)
 */
export const BOARD_KIND_PRESS = `110`;

/**
 * 보드 종류 (필란트로피, 파트너십)
 */
export const BOARD_KIND_PARTNER = `140`;

/**
 * 보드 종류 (학교후원)
 */
export const BOARD_KIND_SCHOOL = `170`;

/**
 * 보드 종류 (이벤트)
 */
export const BOARD_KIND_EVENT = `200`;

/**
 * 보드 종류 (FAQ)
 */
export const BOARD_KIND_FAQ = `260`;

/**
 * 보드 종류 (소식 (뉴스))
 */
export const BOARD_KIND_NEWS = `280`;

/**
 * 보드 종류 (소식 (성명))
 */
export const BOARD_KIND_PUBLIC = `285`;

/**
 * 보드 종류 (소식 (스토리))
 */
export const BOARD_KIND_STORY = `290`;

/**
 * 보드 종류 (어린이 지구촌 체험관 소식)
 */
export const BOARD_KIND_VILLAGE = `291`;

/**
 * 보드 종류 (Party For UNICEF 파티 이야기)
 */
export const BOARD_KIND_PARTY = `293`;

/**
 * 보드 종류 (소식 (인터뷰))
 */
export const BOARD_KIND_INTERVIEW = `295`;

/**
 * 보드 종류 (후원자 현장방문)
 */
export const BOARD_KIND_FIELDTRIP = `300`;

/**
 * 보드 종류 (걸어온길 (기간별))
 */
export const BOARD_KIND_PERIOD = `310`;

/**
 * 보드 종류 (걸어온길 (연도별))
 */
export const BOARD_KIND_YEAR = `315`;

/**
 * 보드 종류 (자료실)
 */
export const BOARD_KIND_PUBLICATION = `400`;

/**
 * 보드 카테고리 이름 Map
 */
export const BOARD_CATEGORIES: any = {
  [BOARD_KIND_NOTICE]: `공지사항`,
  [BOARD_KIND_PRESS]: `보도자료`,
  [BOARD_KIND_NEWS]: `뉴스`,
  [BOARD_KIND_STORY]: `스토리`,
  [BOARD_KIND_INTERVIEW]: `인터뷰`,
  [BOARD_KIND_EVENT]: `이벤트`,
  [BOARD_KIND_FAQ]: `FAQ`,
  [BOARD_KIND_PARTNER]: `필란트로피, 파트너십`,
  [BOARD_KIND_VILLAGE]: `어린이 지구촌 체험관 소식`,
  [BOARD_KIND_FIELDTRIP]: `후원자참여`,
  [BOARD_KIND_PUBLIC]: `성명`,
};
/**
 * 소식 카테고리 종류 (생존과 발달)
 */
export const NEWS_CATEGORY_SAVING = `A001`;

/**
 * 소식 카테고리 종류 (교육)
 */
export const NEWS_CATEGORY_EDUCATION = `A005`;

/**
 * 소식 카테고리 종류 (보호)
 */
export const NEWS_CATEGORY_PROTECTION = `A006`;

/**
 * 소식 카테고리 종류 (식수와 위생)
 */
export const NEWS_CATEGORY_WASH = `A003`;

/**
 * 소식 카테고리 종류 (공평한 기회)
 */
export const NEWS_CATEGORY_CHANCE = `A007`;

/**
 * 소식 카테고리 종류 (코로나19와 백신)
 */
export const NEWS_CATEGORY_COVID = `A015`;

/**
 * 소식 카테고리 종류 (인도주의 지원)
 */
export const NEWS_CATEGORY_HUMANITARIAN = `A004`;

/**
 * 소식 카테고리 종류 (난민 어린이)
 */
export const NEWS_CATEGORY_CONFLICT = `A008`;

/**
 * 소식 카테고리 종류 (스쿨스포아시아)
 */
export const NEWS_CATEGORY_SCHOOLFORASIA = `A019`;

/**
 * 소식 카테고리 종류 (기후위기 대응)
 */
export const NEWS_CATEGORY_CLIMATE = `A010`;

/**
 * 소식 카테고리 종류 (이노베이션)
 */
export const NEWS_CATEGORY_INNOVATION = `A011`;

/**
 * 소식 카테고리 종류 (성평등)
 */
export const NEWS_CATEGORY_GENDER = `A002`;

/**
 * 소식 카테고리 종류 (장애어린이)
 */
export const NEWS_CATEGORY_DISABILITY = `A020`;

/**
 * 소식 카테고리 종류 (조사와 연구)
 */
export const NEWS_CATEGORY_RESEARCH = `A021`;

/**
 * 소식 카테고리 종류 (아동의회)
 */
export const NEWS_CATEGORY_COUNCIL = `A018`;

/**
 * 소식 카테고리 종류 (자원봉사)
 */
export const NEWS_CATEGORY_VOLUNTEER = `A016`;

/**
 * 소식 카테고리 종류 (아동권리협약)
 */
export const NEWS_CATEGORY_CHILD_RIGHTS = `A014`;

/**
 * 소식 카테고리 종류 (아동권리협약이행)
 */
export const NEWS_CATEGORY_CHILD_RIGHTS_IMPLEMENT = `A022`;

/**
 * 소식 카테고리 종류 (입법활동)
 */
export const NEWS_CATEGORY_LEGISLATIVE_ACTIVITY = `A023`;

/**
 * 소식 카테고리 종류 (아동친화도시)
 */
export const NEWS_CATEGORY_CHILD_CITY = `A024`;

/**
 * 소식 카테고리 종류 (권리교육)
 */
export const NEWS_CATEGORY_RIGHTS_EDUCATION = `A025`;

/**
 * 소식 카테고리 종류 (아동친화학교)
 */
export const NEWS_CATEGORY_CHILD_SCHOOL = `A026`;

/**
 * 소식 카테고리 종류 (모유수유캠페인)
 */
export const NEWS_CATEGORY_BREASTFEEDING = `A027`;

/**
 * 소식 카테고리 종류 (아동친화기업)
 */
export const NEWS_CATEGORY_CHILD_COMPANY = `A028`;

/**
 * 소식 카테고리 종류 (난민어린이)
 */
export const NEWS_CATEGORY_CHILD_REFUGEE = `A029`;

/**
 * 소식 카테고리 종류 (행사/이벤트)
 */
export const NEWS_CATEGORY_EVENT = `A030`;

/**
 * 소식 카테고리 종류 (현장방문)
 */
export const NEWS_CATEGORY_ONSITE_VISITS = `A031`;

/**
 * 소식 카테고리 종류 (참여활동)
 */
export const NEWS_CATEGORY_PARTICIPATE_ACTIVITY = `A032`;

/**
 * 소식 카테고리 종류 (후원자이야기)
 */
export const NEWS_CATEGORY_DONOR_STORY = `A033`;

/**
 * 소식 카테고리 종류 (긍정양육)
 */
export const NEWS_CATEGORY_POSITIVE_PARENTING = `A034`;

/**
 * 소식 카테고리 종류 (마음건강)
 */
export const NEWS_CATEGORY_MIND_HEALTHY = `A035`;

/**
 * 소식 카테고리 종류 (한국정부협력)
 */
export const NEWS_CATEGORY_GOVERNMENT = `A013`;

/**
 * 소식 카테고리 종류 (아너스/고액)
 */
export const NEWS_CATEGORY_HONORS_CLUB = `A017`;

/**
 * 소식 카테고리 이름 Map
 * (이름에는 띄어쓰기 없어야함)
 */
export const NEWS_CATEGORIES: any = {
  [NEWS_CATEGORY_SAVING]: `생존과발달`,
  [NEWS_CATEGORY_EDUCATION]: `교육`,
  [NEWS_CATEGORY_PROTECTION]: `보호`,
  [NEWS_CATEGORY_WASH]: `안전한환경`,
  [NEWS_CATEGORY_CHANCE]: `공평한기회`,
  [NEWS_CATEGORY_COVID]: `코로나19와백신`,
  [NEWS_CATEGORY_HUMANITARIAN]: `인도주의지원`,
  [NEWS_CATEGORY_CONFLICT]: `난민어린이`,
  [NEWS_CATEGORY_CLIMATE]: `기후위기대응`,
  [NEWS_CATEGORY_INNOVATION]: `이노베이션`,
  [NEWS_CATEGORY_GENDER]: `성평등`,
  [NEWS_CATEGORY_DISABILITY]: `장애어린이`,
  [NEWS_CATEGORY_RESEARCH]: `조사와 연구`,
  [NEWS_CATEGORY_COUNCIL]: `아동의회`,
  [NEWS_CATEGORY_VOLUNTEER]: `자원봉사`,
  [NEWS_CATEGORY_CHILD_RIGHTS]: `아동권리협약`,
  [NEWS_CATEGORY_CHILD_RIGHTS_IMPLEMENT]: `아동권리협약이행`,
  [NEWS_CATEGORY_LEGISLATIVE_ACTIVITY]: `정책개선활동`,
  [NEWS_CATEGORY_CHILD_CITY]: `아동친화도시`,
  [NEWS_CATEGORY_RIGHTS_EDUCATION]: `권리교육`,
  [NEWS_CATEGORY_CHILD_SCHOOL]: `아동친화학교`,
  [NEWS_CATEGORY_BREASTFEEDING]: `모유수유캠페인`,
  [NEWS_CATEGORY_CHILD_COMPANY]: `아동친화기업`,
  [NEWS_CATEGORY_CHILD_REFUGEE]: `난민어린이`,
  [NEWS_CATEGORY_EVENT]: `후원자행사·이벤트`,
  [NEWS_CATEGORY_ONSITE_VISITS]: `후원자현장방문`,
  [NEWS_CATEGORY_POSITIVE_PARENTING]: `긍정양육`,
  [NEWS_CATEGORY_MIND_HEALTHY]: `마음건강`,
  [NEWS_CATEGORY_PARTICIPATE_ACTIVITY]: `후원자참여활동`,
  [NEWS_CATEGORY_DONOR_STORY]: `후원자이야기`,
  [NEWS_CATEGORY_SCHOOLFORASIA]: `스쿨스포아시아`,
  [NEWS_CATEGORY_GOVERNMENT]: `한국정부협력`,
  [NEWS_CATEGORY_HONORS_CLUB]: `아너스/고액`,
};

/**
 * 어린이 스토리 종류 (교육)
 */
export const STORY_CATEGORY_EDUCATION = `ST02`;

/**
 * 어린이 스토리 종류 (생존과 발달)
 */
export const STORY_CATEGORY_SAVING = `ST01`;

/**
 * 어린이 스토리 종류 (보호)
 */
export const STORY_CATEGORY_PROTECTION = `ST03`;

/**
 * 어린이 스토리 종류 (식수와 위생)
 */
export const STORY_CATEGORY_WASH = `ST04`;

/**
 * 어린이 스토리 종류 (공평한 기회)
 */
export const STORY_CATEGORY_CHANCE = `ST05`;

/**
 * 어린이 스토리 종류 (코로나 19와 백신)
 */
export const STORY_CATEGORY_COVID = `ST11`;

/**
 * 어린이 스토리 종류 (인도주의 지원)
 */
export const STORY_CATEGORY_HUMANITARIAN = `ST12`;

/**
 * 어린이 스토리 종류 (난민 어린이)
 */
export const STORY_CATEGORY_CONFLICT = `ST13`;

/**
 * 어린이 스토리 종류 (기후위기 대응)
 */
export const STORY_CATEGORY_CLIMATE = `ST06`;

/**
 * 어린이 스토리 종류 (이노베이션)
 */
export const STORY_CATEGORY_INNOVATION = `ST07`;

/**
 * 어린이 스토리 종류 (성평등)
 */
export const STORY_CATEGORY_GENDER = `ST08`;

/**
 * 어린이 스토리 종류 (장애어린이)
 */
export const STORY_CATEGORY_DISABILITY = `ST09`;

/**
 * 어린이 스토리 종류 (조사와 연구)
 */
export const STORY_CATEGORY_RESEARCH = `ST10`;

/**
 * 어린이 스토리 이름 Map
 */
export const CHILD_STORY_CATEGORIES: any = {
  [STORY_CATEGORY_SAVING]: `생존과 발달`,
  [STORY_CATEGORY_EDUCATION]: `교육`,
  [STORY_CATEGORY_PROTECTION]: `보호`,
  [STORY_CATEGORY_WASH]: `식수와 위셍`,
  [STORY_CATEGORY_CHANCE]: `공평한 기회`,
  [STORY_CATEGORY_COVID]: `코로나19와 백신`,
  [STORY_CATEGORY_HUMANITARIAN]: `인도주의 지원`,
  [STORY_CATEGORY_CONFLICT]: `난민 어린이`,
  [STORY_CATEGORY_CLIMATE]: `기후위기 대응`,
  [STORY_CATEGORY_INNOVATION]: `이노베이션`,
  [STORY_CATEGORY_GENDER]: `성평등`,
  [STORY_CATEGORY_DISABILITY]: `장애어린이`,
  [STORY_CATEGORY_RESEARCH]: `조사와 연구`,
};

/**
 * 활동 스토리 종류 (ENDviolence 의 loveMySelf)
 */
export const STORY_CATEGORY_LOVEMYSELF = `EV01`;

/**
 * 활동 스토리 종류 (ENDviolence 의 loveMySelf (en))
 */
export const STORY_CATEGORY_LOVEMYSELF_EN = `EV02`;

/**
 * 한국정부협력 스토리 종류 (주요파트너십)
 */
export const STORY_CATEGORY_GOVERNMENT1 = `GP01`;

/**
 * 한국정부협력 스토리 종류 (국회친구들)
 */
export const STORY_CATEGORY_GOVERNMENT2 = `GP02`;

/**
 * 한국정부협력 스토리 종류 (옹호활동)
 */
export const STORY_CATEGORY_GOVERNMENT3 = `GP03`;

// 이벤트 목록 uri
export const EVENT_LIST_URI = `/involve/event/`;

/**
 * 자료실 카테코리 코드
 */
export const COMMON_CODE_PUBLICATION = `KW99`;

/**
 * '아기에게 친근한 병원 카테고리' 코드
 */
export const RIGHIS_FRIENDLY_HOSPITAL = `240`;

/**
 * '엄마에게 친근한 일터 카테고리' 코드
 */
export const MOTHER_FRIENDLY_WORKPLACE = `230`;

// 1:1 문의 유형 데이터 (as-is와 to-be가 약간 다른 부분이 있어 value와 label을 분리)
export const ONE_TO_ONE_CATEGORIES: { [key: string]: string } = {
  개인후원: `개인 후원`,
  '팔찌, 반지 관련': `팔찌, 반지`,
  '일반홍보,자료문의': `홍보, 자료문의`,
  자원봉사: `자원봉사`,
  채용: `채용`,
  '아동권리·아동친화사업': `아동권리·아동친화사업`,
  학교캠페인: `학교캠페인`,
  모유수유: `모유수유`,
  기업후원: `기업후원`,
  기념기부: `기념기부`,
  동전모금: `동전모금`,
  후원병원: `후원병원`,
  유산기부: `유산기부`,
  '재단/비영리/기관 및 단체': `재단/비영리/기관 및 단체`,
  아우인형: `아우인형`,
};
