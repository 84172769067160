import { VillageStep1Data } from './VillageStep1';
import { AttFileControllerService } from '@/__generated__/CommonApi';
import { ChildParticipationControllerService } from '@/__generated__/FrontApi';
import Attachment from '@/components/Attachment';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import FormTextTip from '@/components/Form/FormTextTip';
import LabelText from '@/components/Form/LabelText';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputText from '@/components/Input/InputText';
import Select from '@/components/Select';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { mobileRegex } from '@/helpers/ValidationHelper';
import { usePopupStore } from '@/stores/PopupStore';
import React, { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const StepHead = styled.div`
  line-height: 1.2;
  padding: 29px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  &.agree-head {
    margin-top: 40px;
    border-top: 1px solid #e5e6e8;
    border-bottom: 1px solid #b7b8ba;
    padding-bottom: 16px;
  }
`;

const StepContainer = styled.div`
  .time-flex {
    margin-top: 92px;
    & > li {
      width: 50%;
      padding: 0 16px;

      button {
        width: 100%;
      }
    }
  }

  .btn-wrap {
    margin-top: 126px;
    text-align: center;

    button {
      width: 100%;
      max-width: 256px;
    }
  }

  .form-container {
    .col-flex {
      .col-left {
        width: 160px;
      }
      .col-right {
        width: calc(100% - 180px);
      }
    }

    ${breakpoint(`mobile`)} {
      .col-flex {
        margin: 0 -6px;
        .col-left,
        .col-right {
          width: 50%;
          padding: 0 6px;
        }
      }
    }

    .FormGroup {
      &.f1 {
        padding-bottom: 40px;
        border-bottom: 1px solid #e5e6e8;

        ${breakpoint(`mobile`)} {
          .col-flex {
            .col-left,
            .col-right {
              width: 100%;
            }
            .col-right {
              margin-top: 8px;
            }
          }
        }
      }

      .tip {
        margin-top: 8px;
        font-size: 14px;
        ${breakpoint(640)} {
          font-size: 12px;
        }
      }
    }

    .btn-down {
      width: 100%;
      max-width: 320px;
      margin-top: 16px;
    }

    ${breakpoint(`mobile`)} {
      .btn-down {
        max-width: 100%;
      }
    }
  }
`;
const AgreeTable = styled.div`
  margin: 8px 0;
  table {
    margin-top: 0;
    border-top: 2px solid #2d2926;
    th {
      font-weight: bold;
      text-align: center;
    }
    td {
      text-align: center;
    }
    th,
    td {
      height: auto;
      padding: 12px 4px;
      font-size: 16px;
      line-height: normal;
      border-top: none;
    }
    td {
      border-bottom: 1px solid #ddd;
    }
  }
  ${breakpoint(640)} {
    table {
      th,
      td {
        font-size: 14px;
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VillageStep2Data {
  selectDate: string;
  selectTime: string;
  agency: string;
  organType: string;
  personnelCount: string;
  contents?: string;
  applyUserName: string;
  applyUserPhone: string;
  applyUserEmail: string;
  isAgreeYn1: boolean;
  isAgreeYn2: boolean;
  fileAttach: string;
}

interface VillageStep2Props {
  onSubmit: (data: VillageStep2Data) => void;
  step1Data?: VillageStep1Data;
  queryParams: any;
  data: any;
}

const VillageStep2: FC<VillageStep2Props> = ({
  onSubmit,
  step1Data,
  ...props
}) => {
  const selectDate = step1Data?.userSelectDate;
  const selectTime = step1Data?.userTimeType;

  const popupStore = usePopupStore();

  const {
    handleSubmit,
    setValue,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<VillageStep2Data>({
    defaultValues: {
      selectDate,
      selectTime,
      agency: ``,
      organType: ``,
      personnelCount: ``,
      contents: ``,
      applyUserName: ``,
      applyUserPhone: ``,
      applyUserEmail: ``,
      isAgreeYn1: false,
      isAgreeYn2: false,
    },
  });

  // 첨부파일
  const [files, setFiles] = useState<File[]>([]);
  const [fileState, setFileState] = useState<boolean>();

  // 날짜 재선택
  const currentChild = (data: number) => {
    props.currentParent(data);
  };

  const onSubmitForm: SubmitHandler<VillageStep2Data> = useCallback(
    async ({
      agency,
      selectDate,
      selectTime,
      organType,
      personnelCount,
      contents,
      applyUserName,
      applyUserPhone,
      applyUserEmail,
      isAgreeYn1,
      isAgreeYn2,
      ...formData
    }) => {
      try {
        let applyAttGrpNo: any;
        // 파일이 있다면
        if (files.length > 0) {
          const result = await AttFileControllerService.uploadUsingPost({
            file: files,
          });
          applyAttGrpNo = result.data.attGrpNo;
          console.log(applyAttGrpNo);
        }

        const {
          resultCode,
          resultMessage,
        } = await ChildParticipationControllerService.childApplyInsertUsingPost(
          {
            selectDate,
            selectTime,
            agency,
            organType,
            personnelCount,
            contents,
            applyUserName,
            applyUserPhone,
            applyUserEmail,
            isAgreeYn1: isAgreeYn1 ? `Y` : `N`,
            isAgreeYn2: isAgreeYn2 ? `Y` : `N`,
            applyAttGrpNo,
            ...formData,
          },
        );
        // 성공후 폼 리셋
        onSubmit(applyAttGrpNo);
        reset();
        setFiles([]);
        setFileState(false);
      } catch (e) {
        console.error(e);
        popupStore.show(
          `유니세프어린이지구촌체험관 신청에 실패했습니다. 다시 시도해주세요.`,
        );
      }
    },
    [files, popupStore, reset],
  );

  return (
    <StepContainer>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="form-container">
          <FormGroup className="FormGroup f1">
            <LabelText require>체험일시</LabelText>
            <div className="col-flex flex">
              <div className="col-left">
                <InputText
                  placeholder=""
                  name="selectDate"
                  ref={register}
                  readOnly
                  value={selectDate}
                />
              </div>
              <div className="col-right">
                <input
                  type="hidden"
                  name="selectTime"
                  value={selectTime}
                  ref={register}
                />
                <InputText
                  placeholder=""
                  name="selectTimeVisible"
                  readOnly
                  value={
                    selectTime === `10`
                      ? `10시 30분 ~ 10시 55분`
                      : `14시 30분 ~ 14시 55분`
                  }
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>기관명</LabelText>
            <InputText
              placeholder="기관명 입력"
              name="agency"
              ref={register({
                required: {
                  value: true,
                  message: `기관명을 입력해주세요.`,
                },
              })}
            />
            {errors.agency && (
              <ErrorMessage>{errors.agency.message}</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup className="FormGroup">
            <LabelText require>신청인원 및 구분</LabelText>
            <div className="col-flex flex">
              <div className="col-left">
                <Select
                  name="organType"
                  ref={register({
                    required: {
                      value: true,
                      message: `구분을 선택해주세요.`,
                    },
                  })}
                >
                  <option value="" disabled>
                    구분 선택
                  </option>
                  <option value="초등">초등</option>
                  <option value="중등">중등</option>
                  <option value="고등">고등</option>
                </Select>
              </div>
              <div className="col-right">
                <InputText
                  type="number"
                  placeholder="인원수 입력 (숫자)"
                  name="personnelCount"
                  ref={register({
                    required: {
                      value: true,
                      message: `신청인원을 입력해주세요.`,
                    },
                  })}
                />
              </div>
            </div>
            {errors.organType && (
              <ErrorMessage>{errors.organType.message}</ErrorMessage>
            )}
            {errors.personnelCount && (
              <ErrorMessage>{errors.personnelCount.message}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>신청서</LabelText>
            <input
              type="hidden"
              name="fileAttach"
              value={files}
              ref={register({
                required: {
                  value: true,
                  message: `신청서를 첨부해주세요.`,
                },
                min: {
                  value: 1,
                  message: `신청서를 첨부해주세요.`,
                },
              })}
            />
            <Attachment
              accept="application/pdf"
              multiple
              files={files}
              name="fileAttach"
              onFilesChange={setFiles}
            />
            {!files.length && errors.fileAttach && (
              <ErrorMessage>{errors.fileAttach.message}</ErrorMessage>
            )}
            <p className="tip">
              <FormTextTip>
                기관장의 서명 및 직인이 포함된 신청서를 반드시 첨부해야 합니다.
              </FormTextTip>
            </p>
            <Button
              outline
              full
              ico="down"
              className="btn-down"
              onClick={() =>
                window.open(
                  `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYw%3D%3D&dispFileNm=online_program_apply_format.zip&svrFileNm=online_program_apply_format.zip`,
                )
              }
            >
              <span className="ico">참가신청서 다운로드</span>
            </Button>
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText>기타 요청사항</LabelText>
            <InputText
              placeholder="기타 요청사항 입력(선택)"
              name="contents"
              ref={register}
              defaultValue=""
            />
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>신청자명</LabelText>
            <InputText
              placeholder="신청자명 입력"
              name="applyUserName"
              ref={register({
                required: {
                  value: true,
                  message: `신청자명을 입력해주세요.`,
                },
              })}
            />
            {errors.applyUserName && (
              <ErrorMessage>{errors.applyUserName.message}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>휴대폰번호</LabelText>
            <InputText
              placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
              name="applyUserPhone"
              ref={register({
                required: {
                  value: true,
                  message: `휴대폰번호를 입력해주세요.`,
                },
                pattern: {
                  value: mobileRegex,
                  message: `올바른 휴대폰번호가 아닙니다.`,
                },
                validate: {
                  isValidRange: (value) =>
                    (value.substr(0, 3) === `010` && value.length === 11) ||
                    (value.substr(0, 3) !== `010` && value.length === 10) ||
                    `휴대폰번호 자릿수를 확인해주세요`,
                },
              })}
            />
            {errors.applyUserPhone && (
              <ErrorMessage>{errors.applyUserPhone.message}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>이메일</LabelText>
            <InputText
              placeholder="이메일주소 입력"
              name="applyUserEmail"
              ref={register({
                required: {
                  value: true,
                  message: `이메일주소를 입력해주세요.`,
                },
                pattern: {
                  value: /^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}$/,
                  message: `올바른 이메일 형식이 아닙니다.`,
                },
              })}
            />
            {errors.applyUserEmail && (
              <ErrorMessage>{errors.applyUserEmail.message}</ErrorMessage>
            )}
            <p className="tip">
              <FormTextTip>
                신청확인 후 '대기중' 상태로, [확인 이메일]이 발송됩니다.
              </FormTextTip>
              <FormTextTip>
                유니세프 승인을 거쳐 신청이 확정되면 [확정 이메일]을
                보내드립니다.
              </FormTextTip>
            </p>
          </FormGroup>
          <StepHead>
            <h2>
              <Tit size="s3">개인정보 수집 ∙ 이용 동의</Tit>
            </h2>
          </StepHead>
          <FormGroup className="chk-group full">
            <InputCheckbox
              label="[필수] 개인정보 수집 ∙ 이용 동의"
              name="isAgreeYn1"
              ref={register({
                required: {
                  value: true,
                  message: `개인정보 수집 ∙ 이용 동의에 체크해주세요.`,
                },
              })}
            />
            <AgreeTable>
              <table>
                <tbody>
                  <tr>
                    <th>목적</th>
                    <th>항목</th>
                    <th>보유기간</th>
                  </tr>
                  <tr>
                    <td>참가신청 확인 및 안내</td>
                    <td>
                      기관명, 기관주소, 기관연락처, 학년, 안솔자명, 인솔자연락처
                    </td>
                    <td className="ellipsis">행사 종료 후 3개월 이내 파기</td>
                  </tr>
                </tbody>
              </table>
            </AgreeTable>
            {errors.isAgreeYn1 && (
              <ErrorMessage>{errors.isAgreeYn1.message}</ErrorMessage>
            )}
            <p className="tip">
              <FormTextTip>
                위의 개인정보 수집 ∙ 이용에 동의하지 않을 경우 참가신청이
                제한됩니다.
              </FormTextTip>
            </p>
          </FormGroup>
          <FormGroup className="chk-group full">
            <InputCheckbox
              label="[선택] 개인정보 수집 ∙ 이용 동의"
              name="isAgreeYn2"
              ref={register}
            />
            <AgreeTable>
              <table>
                <tr>
                  <th>목적</th>
                  <th>항목</th>
                  <th>보유기간</th>
                </tr>
                <tr>
                  <td>아동권리 교육 및 행사 안내</td>
                  <td>기관명, 인솔자명, 인솔자연락처</td>
                  <td className="ellipsis">2년</td>
                </tr>
              </table>
            </AgreeTable>
            <p className="tip">
              <FormTextTip>
                위의 개인정보 수집 ∙ 이용에 동의하지 않더라도 참가신청이
                가능합니다.
              </FormTextTip>
            </p>
          </FormGroup>
        </div>
        <ul className="time-flex flex">
          <li>
            <Button
              outline
              size="sm"
              onClick={() => {
                currentChild(1);
              }}
            >
              날짜 재선택
            </Button>
          </li>
          <li>
            <Button size="sm" type="submit">
              신청하기
            </Button>
          </li>
        </ul>
      </form>
    </StepContainer>
  );
};
export default VillageStep2;
