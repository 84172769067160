import { BtnClose } from './Button';
import Modal from './Modal';
import { Tit } from './Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface ModalProps {
  isOpen?: boolean;
  // 모달 바깥쪽 누를시 닫을수 있는지 여부
  dismissable?: boolean;
  // 닫힘요청시 콜백
  onRequestClose: () => void;
  maxWidth?: string;
}

interface ThumbnailLayerProps extends ModalProps {
  PopSeq: string | number;
  PopTitle: string;
  contents?: string;
}

const PopCcontainer = styled.div`
  padding: 0 32px;

  ${breakpoint(`tablet`)} {
    padding: 0 20px;
  }
`;

const PopupTitle = styled.div`
  border-bottom: 1px solid #1cabe2;
  position: relative;
  padding: 22px 0;

  ${BtnClose} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    &::before,
    &::after {
      background: #1cabe2;
    }
  }
`;

const PopContent = styled.div`
  padding-top: 32px;
  padding-bottom: 100px;

  .dec-wrap {
    .tit {
      display: block;
      margin-bottom: 16px;
    }
    img {
      display: block;
      margin: 0 auto 24px;
      max-width: 100%;
    }
  }

  img {
    display: block;
    margin: 0 auto;
  }

  ${breakpoint(`tablet`)} {
    padding-top: 24px;
    padding-bottom: 90px;
  }
`;

const ThumbnailLayerPopup: FC<ThumbnailLayerProps> = ({
  onRequestClose,
  ...props
}) => (
  <Modal onRequestClose={onRequestClose} maxWidth="640px" {...props}>
    <PopCcontainer>
      <PopupTitle>
        <h2>
          <Tit
            size="s3"
            color="sky"
            dangerouslySetInnerHTML={{ __html: props.PopTitle }}
          />
        </h2>
        <BtnClose onClick={() => onRequestClose()}>
          <span className="a11y">닫기</span>
        </BtnClose>
      </PopupTitle>
      <PopContent>
        <div className="dec-wrap">
          <p
            className="dec"
            dangerouslySetInnerHTML={{ __html: props.contents }}
          />
        </div>
      </PopContent>
    </PopCcontainer>
  </Modal>
);

export default ThumbnailLayerPopup;
