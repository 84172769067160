import { ChildParticipationControllerService } from '@/__generated__/FrontApi';
import Button from '@/components/Button';
import FormGroup from '@/components/Form/FormGroup';
import FormTextTip from '@/components/Form/FormTextTip';
import LabelText from '@/components/Form/LabelText';
import InputText from '@/components/Input/InputText';
import Select from '@/components/Select';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { usePopupStore } from '@/stores/PopupStore';
import React, { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const StepHead = styled.div`
  line-height: 1.2;
  padding: 29px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  &.agree-head {
    margin-top: 40px;
    border-top: 1px solid #e5e6e8;
    border-bottom: 1px solid #b7b8ba;
    padding-bottom: 16px;
  }
`;

const StepContainer = styled.div`
  padding: 48px 0;

  .btn-wrap {
    margin-top: 126px;
    text-align: center;

    button {
      width: 100%;
      max-width: 256px;
    }
  }

  .form-container {
    .col-flex {
      .col-left {
        width: 176px;
      }
      .col-right {
        width: calc(100% - 180px);
        .complete {
          overflow: hidden;
          color: #828385;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    ${breakpoint(`mobile`)} {
      .col-flex {
        margin: 0 -6px;
        .col-left,
        .col-right {
          width: 50%;
          padding: 0 6px;
        }
      }
    }

    .FormGroup {
      &.f1 {
        padding-bottom: 40px;
        border-bottom: 1px solid #e5e6e8;

        ${breakpoint(`mobile`)} {
          .col-flex {
            .col-left,
            .col-right {
              width: 100%;
            }
            .col-right {
              margin-top: 8px;
            }
          }
        }
      }

      .tip {
        margin-top: 8px;
      }
    }

    .btn-down {
      width: 100%;
      max-width: 320px;
      margin-top: 16px;
    }

    ${breakpoint(`mobile`)} {
      .btn-down {
        max-width: 100%;
      }
    }
  }
`;
const AgreeTable = styled.div`
  margin: 8px 0;
  table {
    margin-top: 0;
    border-top: 2px solid #2d2926;
    th {
      font-weight: bold;
      text-align: center;
    }
    td {
      text-align: center;
    }
    th,
    td {
      height: auto;
      padding: 12px 4px;
      font-size: 16px;
      line-height: normal;
      border-top: none;
    }
    td {
      border-bottom: 1px solid #ddd;
    }
  }
  ${breakpoint(640)} {
    table {
      th,
      td {
        font-size: 14px;
      }
    }
  }
`;
export interface VillageApplyInfoData {
  selectDate: string;
  selectTime: string;
  agency: string;
  organType: string;
  personnelCount: string;
  contents?: string;
  applyUserName: string;
  applyUserPhone: string;
  applyUserEmail: string;
  isAgreeYn1: boolean;
  isAgreeYn2: boolean;
  fileAttach: string;
}
interface VillageCancelData {
  indexNumber: string;
}

const VillageApplyInfo: FC<VillageApplyInfoData> = ({ ...props }) => {
  const {
    handleSubmit,
    setValue,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<VillageCancelData>({
    defaultValues: {
      indexNumber: ``,
    },
  });

  const popupStore = usePopupStore();

  const onSubmitCancel: SubmitHandler<VillageCancelData> = useCallback(async () => {
    try {
      const {
        resultCode,
        resultMessage,
      } = await ChildParticipationControllerService.applyCancelModifyUsingPost({
        indexNumber: props.applyData.indexNumber,
      });
      popupStore.show(`어린이지구촌체험관 신청 취소 요청이 완료되었습니다.`);
    } catch (e) {
      console.error(e);
    }
  }, [popupStore]);

  return (
    <StepContainer>
      <form>
        <p
          css={`
            margin-bottom: 48px;
            line-height: 1.7;
          `}
        >
          {props.applyData.applyUserName}님이 신청하신 내역입니다. <br />
          다중 신청의 경우, 참여일이 임박한 내역만 보여집니다.
        </p>
        <div className="form-container">
          <FormGroup>
            <LabelText>상태</LabelText>
            <div className="col-flex flex">
              <div className="col-right">
                <InputText value={props.applyData.status} readOnly />
              </div>
              <div className="col-left">
                {props.applyData.status === `취소` ? (
                  <Button
                    disabled
                    css={`
                      width: 100%;
                    `}
                  >
                    취소
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      onSubmitCancel(props.applyData.indexNumber);
                    }}
                    css={`
                      width: 100%;
                    `}
                  >
                    취소
                  </Button>
                )}
              </div>
            </div>
          </FormGroup>
          <FormGroup className="FormGroup f1">
            <LabelText require>체험일시</LabelText>
            <div className="col-flex flex">
              <div className="col-left">
                <InputText
                  placeholder=""
                  name="selectDate"
                  readOnly
                  value={props.applyData.selectDate}
                />
              </div>
              <div className="col-right">
                <InputText
                  placeholder=""
                  name="selectTime"
                  readOnly
                  value={
                    props.applyData.selectTime === `10`
                      ? `10시 30분 ~ 10시 55분`
                      : `14시 30분 ~ 14시 55분`
                  }
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>기관명</LabelText>
            <InputText
              placeholder="기관명 입력"
              name="agency"
              value={props.applyData.agency}
              readOnly
            />
          </FormGroup>
          <FormGroup className="FormGroup">
            <LabelText require>신청인원 및 구분</LabelText>
            <div className="col-flex flex">
              <div className="col-left">
                <Select
                  name="organType"
                  readOnly
                  value={props.applyData.organType}
                >
                  <option value="" disabled>
                    구분 선택
                  </option>
                  <option value="초등">초등</option>
                  <option value="중등">중등</option>
                  <option value="고등">고등</option>
                </Select>
              </div>
              <div className="col-right">
                <InputText
                  type="number"
                  placeholder="인원수 입력 (숫자)"
                  name="personnelCount"
                  value={props.applyData.personnelCount}
                  readOnly
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>신청서</LabelText>
            <p className="tip">
              <div className="col-flex flex">
                <div className="col-left">
                  <Button
                    disabled
                    outline
                    color="gray"
                    css={`
                      width: 100%;
                      font-size: 16px;
                    `}
                  >
                    파일선택
                  </Button>
                </div>
                <div className="col-right">
                  <p className="complete">{props.applyData.dispFileNm}</p>
                </div>
              </div>
              <FormTextTip>
                기관장의 서명 및 직인이 포함된 신청서를 반드시 첨부해야 합니다.
              </FormTextTip>
            </p>
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText>기타 요청사항</LabelText>
            <InputText
              placeholder="기타 요청사항 입력(선택)"
              name="contents"
              value={props.applyData.contents}
              readOnly
            />
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>신청자명</LabelText>
            <InputText
              placeholder="신청자명 입력"
              name="applyUserName"
              value={props.applyData.applyUserName}
              readOnly
            />
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>휴대폰번호</LabelText>
            <InputText
              placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
              name="applyUserPhone"
              type="number"
              value={props.applyData.applyUserPhone}
              readOnly
            />
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>이메일</LabelText>
            <InputText
              placeholder="이메일주소 입력"
              name="applyUserEmail"
              value={props.applyData.applyUserEmail}
              readOnly
            />
            <p className="tip">
              <FormTextTip>
                신청확인 후 '대기중' 상태로, [확인 이메일]이 발송됩니다.
              </FormTextTip>
              <FormTextTip>
                유니세프 승인을 거쳐 신청이 확정되면 [확정 이메일]을
                보내드립니다.
              </FormTextTip>
            </p>
          </FormGroup>
          {/* <StepHead>
          <h2>
            <Tit size="s3">개인정보 수집 ∙ 이용 동의</Tit>
          </h2>
        </StepHead>
        <FormGroup className="chk-group full">
          <InputCheckbox
            label="참가신청 확인 동의"
            name="isAgreeYn1"
            readOnly
            checked={props.applyData.isAgreeYn1 === `Y`}
          />
          <AgreeTable>
            <table>
              <tr>
                <th>목적</th>
                <th>항목</th>
                <th>보유기간</th>
              </tr>
              <tr>
                <td>참가신청 확인 및 안내</td>
                <td>
                  기관명, 기관주소, 기관연락처, 학년, 안솔자명, 인솔자연락처
                </td>
                <td className="ellipsis">행사 종료 후 3개월 이내 파기</td>
              </tr>
            </table>
          </AgreeTable>
          <p className="tip">
            <FormTextTip>
              위의 참가신청 확인 동의에 동의하지 않을 경우 참가신청이
              제한됩니다.
            </FormTextTip>
          </p>
        </FormGroup>
        <FormGroup className="chk-group full">
          <InputCheckbox
            label="아동권리교육 동의"
            name="isAgreeYn2"
            readOnly
            checked={props.applyData.isAgreeYn1 === `Y`}
          />
          <AgreeTable>
            <table>
              <tr>
                <th>목적</th>
                <th>항목</th>
                <th>보유기간</th>
              </tr>
              <tr>
                <td>아동권리 교육 및 행사 안내</td>
                <td>기관명, 인솔자명, 인솔자연락처</td>
                <td className="ellipsis">2년</td>
              </tr>
            </table>
          </AgreeTable>
          <p className="tip">
            <FormTextTip>
              위의 아동권리교육 동의에 동의하지 않더라도 참가신청이 가능합니다.
            </FormTextTip>
          </p>
        </FormGroup> */}
        </div>
      </form>
    </StepContainer>
  );
};
export default VillageApplyInfo;
