import ThumbnailLayerPopup from '../ThumbnailLayerPopup';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { Link } from 'gatsby';
import React, { FC, ImgHTMLAttributes, useState } from 'react';
import styled from 'styled-components';

interface ThumbnailProps {
  thumbs?: ThumbnailData[];
  newsLists?: NewsData[];
  popLists?: PopData[];
  popUp?: boolean;
  popTitle?: string;
  type?: string;
}
export interface ThumbnailData {
  id: string | number;
  img: ImgHTMLAttributes<any>['src'];
  title: string | undefined;
  link?: string;
  regDate?: string | number;
}
export interface NewsData {
  boardIndexNumber: string | number;
  id: string | number;
  imgAttGrpNoTn: ImgHTMLAttributes<any>['src'];
  subject: string | undefined;
  link?: string;
  firstRegisterDate?: string | number;
  contents?: string;
}

export interface PopData {
  boardIndexNumber: string | number;
  id: string | number;
  imgAttGrpNoTn: ImgHTMLAttributes<any>['src'];
  subject: string | undefined;
  link?: string;
  firstRegisterDate?: string | number;
}
const ThumbnailLayout = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;

  .list-item {
    width: 25%;
    padding: 0 16px;
    margin-bottom: 64px;
    border: 0;
    .link-item {
      height: auto;
      display: block;
      border: 0;
      width: 100%;
      padding: 0;
      text-align: left;

      .img-box {
        padding-top: 100%;
      }
    }
  }

  &.imgOnly {
    .list-item {
      margin-bottom: 32px;
    }

    .link-item {
      padding: 12px;
      position: relative;

      &::before {
        content: '';
        display: none;
        width: 100%;
        height: 100%;
        position: relative;
        position: absolute;
        left: 0;
        top: 0;
        border: 12px solid #1cabe2;
      }

      &:hover::before {
        display: block;
      }
    }

    .dec-wrap {
      display: none;
    }
  }
  ${breakpoint(640)} {
    .list-item {
      width: 50%;
    }
  }
`;

const ThumbnailBody = styled.div`
  padding-top: 24px;
  background: #fff;

  .tit {
    height: 64px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 32px;
  }
  .date {
    margin-top: 16px;
  }
`;

const ThumbnailCardSample: FC<ThumbnailProps> = ({
  thumbs,
  newsLists,
  popUp,
  popTitle,
  type,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [PopupTitle, setPopupTitle] = useState(``); // title
  const [PopupSeq, setPopupSeq] = useState(``); // seq

  const [popContents, setPopContents] = useState(``);

  const open = () => setPopupOpen(true);
  const close = () => setPopupOpen(false);

  return (
    <>
      <ThumbnailLayout className={type || ``}>
        {thumbs &&
          thumbs.map((row) => (
            <li className="list-item" key={row.id}>
              <Link to={row.link} className="link-item">
                <div
                  className="img-box"
                  css={`
                    background-image: url(${row.img});
                  `}
                />
                <ThumbnailBody className="dec-wrap">
                  <Tit size="s4-1" className="tit">
                    {row.title}
                  </Tit>
                  <p className="date">{row.regDate}</p>
                </ThumbnailBody>
              </Link>
            </li>
          ))}
        {newsLists &&
          !popUp &&
          newsLists.map((row) => (
            <li className="list-item" key={row.id}>
              <Link to={row.boardIndexNumber} className="link-item">
                <div
                  className="img-box"
                  css={`
                    background-image: url(${row.imgAttGrpNoTn});
                  `}
                />
                <ThumbnailBody className="dec-wrap">
                  <Tit size="s4-1" className="tit">
                    {row.subject}
                  </Tit>
                  <p className="date">{row.firstRegisterDate}</p>
                </ThumbnailBody>
              </Link>
            </li>
          ))}
        {newsLists &&
          popUp &&
          newsLists.map((row) => (
            <li className="list-item" key={row.id}>
              <button
                type="button"
                onClick={() => {
                  open(true);
                  setPopupTitle(popTitle);
                  setPopupSeq(row.id);
                  setPopContents(row.contents);
                }}
                className="link-item"
              >
                <div
                  className="img-box"
                  css={`
                    background-image: url(${row.imgAttGrpNoTn});
                  `}
                />
                <ThumbnailBody className="dec-wrap">
                  <Tit size="s4-1" className="tit">
                    {row.subject}
                  </Tit>
                  <p className="date">{row.firstRegisterDate}</p>
                </ThumbnailBody>
              </button>
            </li>
          ))}
      </ThumbnailLayout>
      {popUp && popupOpen && (
        <ThumbnailLayerPopup
          isOpen
          PopTitle={PopupTitle}
          contents={popContents}
          PopSeq={setPopupSeq}
          onRequestClose={close}
        />
      )}
    </>
  );
};
export default ThumbnailCardSample;
