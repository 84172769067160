import { BoardCommonControllerService } from '@/__generated__/CommonApi';
import CardImg10 from '@/assets/img/child-participation/village_card_img10@2x.png';
import CardImg1 from '@/assets/img/child-participation/village_card_img1@2x.png';
import CardImg2 from '@/assets/img/child-participation/village_card_img2@2x.png';
import CardImg3 from '@/assets/img/child-participation/village_card_img3@2x.png';
import CardImg4 from '@/assets/img/child-participation/village_card_img4@2x.png';
import CardImg5 from '@/assets/img/child-participation/village_card_img5@2x.png';
import CardImg6 from '@/assets/img/child-participation/village_card_img6@2x.png';
import CardImg7 from '@/assets/img/child-participation/village_card_img7@2x.png';
import CardImg8 from '@/assets/img/child-participation/village_card_img8@2x.png';
import CardImg9 from '@/assets/img/child-participation/village_card_img9@2x.png';
import icoMore from '@/assets/img/icons/arrow_sky@2x.png';
import icoMoreHover from '@/assets/img/icons/arrow_white@2x.png';
import Topbg from '@/assets/img/tmp/child-participation/village_bg@2x.png';
import TopbgM from '@/assets/img/tmp/child-participation/village_bgM@2x.png';
import Bn from '@/assets/img/tmp/child-participation/village_bn@2x.png';
import BnM from '@/assets/img/tmp/child-participation/village_bnM@2x.png';
import VillageLogo from '@/assets/img/tmp/child-participation/village_logo@2x.png';
import { Article } from '@/components/Article/types';
import Button from '@/components/Button';
import ThumbnailCardSample from '@/components/Card/ThumbnailCardSample';
import VillageCard from '@/components/Card/VillageCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import VillageLayerPopup from '@/components/Layer/VillageLayerPopup';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { BOARD_KIND_VILLAGE } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { navigate, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const topData = [
  {
    id: 1,
    pcImage: CardImg1,
    mobileImage: CardImg1,
    dec: `<p class="tit">유니세프 소개</p><span>전 세계 모든 어린이를 위한 유니세프의 차별없는 구호활동에 대해 알아보세요</span>`,
  },
  {
    id: 2,
    pcImage: CardImg2,
    mobileImage: CardImg2,
    dec: `<p class="tit">어린이 보호</p><span>빈곤과 질병, 차별과 폭력으로 고통받는 어린이를 보호하는 방법을 생각해요</span>`,
  },
  {
    id: 3,
    pcImage: CardImg3,
    mobileImage: CardImg3,
    dec: `<p class="tit">VR 여행</p><span>VR (가상현실) 체험을 통해 다른 나라 친구들과 만나요</span>`,
  },
  {
    id: 4,
    pcImage: CardImg4,
    mobileImage: CardImg4,
    dec: `<p class="tit">포토존</p><span>유니세프 직원이 되어 다 함께 사진을 찍어보아요.</span>`,
  },
  {
    id: 5,
    pcImage: CardImg5,
    mobileImage: CardImg5,
    dec: `<p class="tit">아동친화도시</p><span>어린이가 살기 좋은 도시란 어떤 곳일까요? 의견을 적어보세요.

    </span>`,
  },
  {
    id: 6,
    pcImage: CardImg6,
    mobileImage: CardImg6,
    dec: `<p class="tit">보건</p><span>질병으로부터 어린이를 보호하는 구호상자를 만들어 보내요.</span>`,
  },
  {
    id: 7,
    pcImage: CardImg7,
    mobileImage: CardImg7,
    dec: `<p class="tit">영양</p><span>어린이의 발달 상태를 체크하고, 어떤 구호물품이 필요한지 알아보아요.</span>`,
  },
  {
    id: 8,
    pcImage: CardImg8,
    mobileImage: CardImg8,
    dec: `<p class="tit">교육</p><span>어린이가 맘껏 배우고 놀며 행복한 미래를 꿈꿀 수 있는 학교를 만들어요.</span>`,
  },
  {
    id: 9,
    pcImage: CardImg9,
    mobileImage: CardImg9,
    dec: `<p class="tit">어린이의 목소리</p><span>세계 곳곳에서 전하는 이야기에 귀를 기울이고, 응원메시지를 남겨요.</span>`,
  },
  {
    id: 10,
    pcImage: CardImg10,
    mobileImage: CardImg10,
    dec: `<p class="tit">식수와 위생</p><span>물을 구하기 위해 하루 종일 걸어야 하는 어린이의 일상을 체험해요.</span>`,
  },
];
const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동권리사업팀`,
    tel: `02-724-8584~5`,
    email: `village@unicef.or.kr`,
  },
];

const Content = styled.div`
  position: relative;
  background: url(${Topbg}) center top no-repeat;
  background-size: auto 100%;

  ${Container} {
    padding-top: 223px;
    padding-bottom: 84px;
    position: relative;
  }

  .logo {
    position: absolute;
    left: 20px;
    top: 81px;
    max-width: 279px;
  }

  .swiper-container {
    margin-left: -16px;
    margin-right: -16px;
    overflow: hidden;

    .swiper-slide {
      width: 50%;
      padding: 0 16px;
      margin: 0;

      .img-box {
        padding-top: 56.62%;
      }

      .dec {
        margin-top: 32px;
        font-size: 20px;
        line-height: 1.6;

        span {
          display: block;
          margin-top: 16px;
          font-size: 16px;
          font-weight: 400;
          line-height: 2;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    background: url(${TopbgM}) center top no-repeat;
    background-size: 100% auto;
    ${Container} {
      padding-top: 202px;
      padding-bottom: 48px;

      .logo {
        top: 78px;
        max-width: 208px;
      }
    }

    .swiper-container {
      .swiper-slide {
        width: 100%;
        .dec {
          margin-top: 24px;
          font-size: 16px;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }
`;
const Box = styled.div`
  border: 1px solid #1cabe2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 40px;
  margin-top: 64px;

  ${Tit} {
    width: calc(100% - 256px);
  }
  ${Button} {
    width: 100%;
    max-width: 256px;
  }

  ${breakpoint(`tablet`)} {
    padding: 24px;
    margin-top: 48px;
    justify-content: center;
    flex-wrap: wrap;

    ${Tit} {
      width: 100%;
      margin-bottom: 16px;
      text-align: center;
    }
    ${Button} {
      max-width: 148px;
    }
  }
`;

const SectionTop = styled(Section)`
  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      padding-bottom: 30px !important;
    }
  }
`;

const DlContainer = styled.div`
  .tip {
    color: #e2231a;
    font-size: 14px;
    &.blue {
      color: #3162af;
    }
    ${breakpoint(640)} {
      font-size: 12px;
    }
  }

  dl {
    display: flex;
    align-items: flex-start;
    padding: 32px 0;
    border-bottom: 1px solid #f8f9fa;

    &:first-child {
      padding-top: 0;
    }

    dt {
      width: 34%;

      ${Tit} {
        position: relative;
        padding-left: 25px;

        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background: #1cabe2;
          position: absolute;
          left: 0;
          top: 15px;
        }

        strong {
          color: #1cabe2;
        }
      }
    }
    dd {
      width: 66%;
      line-height: 1.7;

      .blit-list {
        li {
          &::before {
            background: #2d2926;
          }
        }
      }
    }
  }

  .cnt-list {
    & > li {
      margin: 0 0 16px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .btn-download {
      margin-top: 8px;
      width: 100%;
      max-width: 352px;
    }
  }
  .btn-flex {
    display: flex;
    max-width: 768px;
    width: 100%;
    margin-top: 8px;

    & > li {
      width: 50%;

      button {
        width: 100%;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    dl {
      dt {
        ${Tit} {
          &::before {
            top: 11px;
          }
        }
      }
      dd {
        padding-left: 24px;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    dl {
      flex-wrap: wrap;

      dt,
      dd {
        width: 100%;
      }
      dd {
        margin-top: 16px;
      }
    }

    .cnt-list {
      .btn-download {
        // margin-left: -20px;
        max-width: 100%;
        width: 100%;
      }
    }

    .btn-flex {
      flex-wrap: wrap;
      max-width: 100%;

      & > li {
        width: 100%;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        ${Button} {
          // margin-left: -20px;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
`;
const SectionHow = styled(Section)`
  padding-bottom: 96px !important;

  ${DlContainer} {
    border-bottom: 1px solid #f8f9fa;
    padding-bottom: 32px;
  }

  ${breakpoint(`mobile`)} {
    ${DlContainer} {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
`;

const SectionBn = styled(Section)`
  button {
    display: block;
    width: 100%;
  }
  .bn {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;

    img {
      width: 100%;
    }
  }
  ${breakpoint(`tablet`)} {
    header {
      br {
        display: none;
      }
    }
  }
`;
const SectionContact = styled(Section)`
  padding-top: 96px !important;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  ${Button} {
    .ico {
      padding-right: 32px;
      display: inline-block;
      width: auto;
      text-align: center;
      position: relative;
      left: 16px;
      &::before {
        width: 32px;
        height: 32px;
        background-image: url(${icoMore});
      }
    }
    &:hover {
      .ico {
        &::before {
          background-image: url(${icoMoreHover});
        }
      }
    }
  }
`;

// 한 페이지당 보여줄 아이템 수
const pagePerCount = 8;

const Village: React.FC<PageProps> = ({ location }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [PopupTitle, setPopupTitle] = useState(``);
  const userStore = useUserStore();
  const popupStore = usePopupStore();

  const [typeApply, setTypeApply] = useState(``);

  const [applyData, setApplyData] = useState([]);
  const [applyChk, setApplyChk] = useState(false);

  const open = () => setPopupOpen(true);
  const close = () => setPopupOpen(false);

  // 현재 페이지
  const [page, setPage] = useState(1);
  // 전체 글 수 (현재 조건기준)
  const [total, setTotal] = useState(0);
  const [newsLists, setNewsLists] = useState<Article[]>([]);

  const hasMore = useMemo(() => total > pagePerCount * page, [page, total]);

  const loadItem = useCallback(
    () =>
      BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_VILLAGE],
        pagePerCount,
        page,
      })
        .then(({ data, totalCount }) => {
          setNewsLists((prevEvents) => [...prevEvents, ...data]);
          setTotal(totalCount);
        })
        .catch((e) => {
          console.error(e);
        }),
    [page],
  );

  // console.log(Storage.getItem(`token`));
  useEffect(() => {
    loadItem();
  }, [loadItem]);

  // 다음 페이지 로드
  const loadNextPage = () => {
    setPage(page + 1);
  };

  return (
    <LayoutWithTitle
      location={location}
      title="어린이지구촌체험관"
      description="for every child, experience"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  &lsquo;유니세프체험관에&rsquo;으로 <br />
                  다양한 지구촌 모습을 만나요!
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                어린이·청소년에게 비대면으로 다양한 지구촌 문제와 유니세프
                활동을 알리는 온라인 프로그램 ‘유니세프체험관에ON’을 운
                <br className="m-hide" />
                영하여 아동청소년이 전 세계의 사회 문제에 관심을 가지고
                아동권리에 대해 배우며, 세계시민의식을 기를 수 있습니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>

        <Content>
          <Container>
            <Image
              pcSrc={VillageLogo}
              mobileSrc={VillageLogo}
              className="logo"
            />
            <VillageCard campaigns={topData} overflowHidden />
          </Container>
        </Content>
      </SectionTop>
      <SectionHow className="by-sub-main-layout">
        <Container>
          <DlContainer>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>누가</strong> 체험 할 수 있나요?
                </Tit>
              </dt>
              <dd>
                초·중·고등학교 등 어린이·청소년 관련 <strong>10명 이상</strong>
                의 단체
                <p className="tip">*개인 신청은 받지 않습니다.</p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>어떤 체험</strong>을 하나요?
                </Tit>
              </dt>
              <dd>
                실시간 원격으로 유니세프 직원과 함께 세계 곳곳에서 펼치는
                유니세프 구호활동을 살피는 활동
                <p
                  className="tip"
                  css={`
                    margin-bottom: 8px;
                  `}
                >
                  *코로나19 확산 방지를 위해 체험관 방문 프로그램은 운영하지
                  않습니다.
                </p>
                <ul className="blit-list">
                  <li>유니세프가 하는 일 (약 5분)</li>
                  <li>
                    영양·교육·식수·보건 관련 유니세프 구호활동 살피기 (약 15분)
                  </li>
                  <li>질의응답 (약 5분)</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>무엇을 준비</strong>해야 하나요?
                </Tit>
              </dt>
              <dd>
                <ul className="blit-list">
                  <li>안정적인 유·무선 인터넷 환경</li>
                  <li>참여 단체당 1기기 (스피커가 있는 컴퓨터)</li>
                  <li>학급, 단체별 개별공간</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>언제, 어떻게</strong> 신청할 수 있나요?
                </Tit>
              </dt>
              <dd>
                <ul className="cnt-list">
                  <li>
                    ① [참가신청서] 작성
                    <br />
                    <Button
                      outline
                      className="btn-download"
                      ico="down"
                      onClick={() =>
                        window.open(
                          `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYw%3D%3D&dispFileNm=online_program_apply_format.zip&svrFileNm=online_program_apply_format.zip`,
                        )
                      }
                    >
                      <span className="ico">참가신청서 다운로드</span>
                    </Button>
                  </li>
                  <li>② 유니세프한국위원회 웹사이트 로그인 및 참가신청</li>
                  <li>
                    ③ 희망일시 선택
                    <br />
                    (월~금 <strong>오전 10시 30분 / 오후 2시 30분</strong> 중
                    택1)
                  </li>
                  <li>
                    ④ ①의 [참가신청서] 첨부하여 신청
                    <br />
                    <p className="tip blue">
                      ※ 근무일 기준 3일 내 신청 확정 안내 이메일을 드립니다
                    </p>
                  </li>
                  <li>
                    ⑤ 온라인 프로그램용 어플리케이션 Zoom을 교실 내 PC에
                    다운로드(
                    <LinkSafe to="https://zoom.us/support/download">
                      https://zoom.us/support/download
                    </LinkSafe>
                    )
                  </li>
                  <li>
                    ⑥ 온라인 프로그램 당일, 인솔자 이메일로 발송된 링크를
                    클릭하여 접속
                  </li>
                  <li>
                    ⑦ 프로그램 종료 후, 만족도 설문조사 참여 (개별 모바일로 입력
                    및 전송)
                    <ol className="flex btn-flex">
                      <li>
                        <Button
                          ico="more"
                          color="gray"
                          outline
                          onClick={() => {
                            window.open(
                              `https://docs.google.com/forms/d/1et2QILRCV15c7ZK4xjyOPfIyK-v2G4_Bh1JHpQdtSoE/viewform?edit_requested=true`,
                            );
                          }}
                        >
                          <span className="ico">아동·청소년용</span>
                        </Button>
                      </li>
                      <li>
                        <Button
                          ico="more"
                          color="gray"
                          outline
                          onClick={() => {
                            window.open(
                              `https://docs.google.com/forms/d/e/1FAIpQLSf0aiFmoDUqwMa6CBYtGWVRcLeU_UQPaityJBc6d2yE0jbEdg/viewform`,
                            );
                          }}
                        >
                          <span className="ico">교사용</span>
                        </Button>
                      </li>
                    </ol>
                  </li>
                </ul>
              </dd>
            </dl>
          </DlContainer>
        </Container>
      </SectionHow>
      <SectionBn>
        <Container>
          {userStore.isLoggedIn() ? (
            <button
              type="button"
              className="bn"
              onClick={() => {
                open();
                setPopupTitle(`유니세프어린이지구촌체험관 신청`);
                setTypeApply(``);
              }}
            >
              <Image pcSrc={Bn} mobileSrc={BnM} />
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                popupStore.show(`로그인 후에 지원 가능합니다.`, {
                  onConfirm: () =>
                    navigate(
                      `/auth/login?redirectTo=/involve/child-participation/village`,
                    ),
                });
              }}
            >
              <Image pcSrc={Bn} mobileSrc={BnM} />
            </button>
          )}
          {applyData.length !== 0 ? (
            <Box>
              <Tit size="s3" color="sky">
                이미 신청하셨나요?
              </Tit>
              <Button
                type="button"
                onClick={() => {
                  open();
                  setPopupTitle(`유니세프어린이지구촌체험관 신청내역`);
                  setTypeApply(`apply`);
                }}
              >
                신청내역 확인
              </Button>
            </Box>
          ) : null}
        </Container>
      </SectionBn>
      <SectionContact className="by-sub-main-layout">
        <Container>
          <header>
            <h2 className="a11y">문의</h2>
          </header>
          <ContactUs itemData={contactData} />
        </Container>
      </SectionContact>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
            </div>
          </SectionHeader>
          <ThumbnailCardSample newsLists={newsLists} />
          {hasMore && (
            <ButtonContainer>
              <Button
                outline
                width={352}
                onClick={() => {
                  loadNextPage();
                }}
              >
                <span className="ico">더 보기</span>
              </Button>
            </ButtonContainer>
          )}
        </Container>
      </SectionNews>
      <VillageLayerPopup
        isOpen={popupOpen}
        PopTitle={PopupTitle}
        onRequestClose={close}
        typeApply={typeApply}
        applyData={applyData}
      />
    </LayoutWithTitle>
  );
};
export default Village;
