import Button from '@/components/Button';
import FormTextTip from '@/components/Form/FormTextTip';
import { Tit } from '@/components/Titles';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const StepContainer = styled.div`
  padding-top: 129px;
  .tip {
    padding-bottom: 96px;
    margin-top: 24px;
    ${FormTextTip} {
      font-size: 16px;
    }
  }

  .btn-wrap {
    text-align: center;

    button {
      width: 100%;
      max-width: 256px;
    }
  }
`;

const VillageStep3: FC = ({ ...props }) => {
  const { handleSubmit } = useForm();
  return (
    <StepContainer>
      <form>
        <Tit size="s1-5" color="sky" weight="normal">
          유니세프어린이지구촌체험관 <br />
          신청이 완료되었습니다.
        </Tit>
        <p className="tip">
          <FormTextTip>
            신청확인 후 '대기중' 상태로, <strong>확인 이메일</strong>이
            발송됩니다.
          </FormTextTip>
          <FormTextTip>
            유니세프 승인을 거쳐 신청이 확정되면 <strong>확정 이메일</strong>을
            보내드립니다.
          </FormTextTip>
        </p>
        <div className="btn-wrap">
          <Button size="sm" type="submit">
            확인
          </Button>
        </div>
      </form>
    </StepContainer>
  );
};
export default VillageStep3;
