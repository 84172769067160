import { PopCcontainer, PopupTitle } from '../Css/Pop';
import Modal from '../Modal';
import { Tit } from '../Titles';
import Step01 from '@/assets/img/tmp/child-participation/village_step_01@2x.png';
import Step02 from '@/assets/img/tmp/child-participation/village_step_02@2x.png';
import { BtnClose } from '@/components/Button';
import VillageApplyInfo from '@/page-blocks/involve/child-participation/VillageApplyInfo';
import VillageStep1, {
  VillageStep1Data,
} from '@/page-blocks/involve/child-participation/VillageStep1';
import VillageStep2, {
  VillageStep2Data,
} from '@/page-blocks/involve/child-participation/VillageStep2';
import VillageStep3 from '@/page-blocks/involve/child-participation/VillageStep3';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';

export interface ModalProps {
  isOpen?: boolean;
  // 모달 바깥쪽 누를시 닫을수 있는지 여부
  dismissable?: boolean;
  // 닫힘요청시 콜백
  onRequestClose: () => void;
  maxWidth?: string;
}

const StepBody = styled.div`
  display: none;
`;
const ToggleBoxActive = css`
  form {
    display: block;
  }
  ${StepBody} {
    display: block;
  }
`;
const ToggleBox = styled.div<{ active: boolean }>`
  form {
    display: none;
  }
  ${StepBody} {
    display: none;
  }
  ${({ active }) => active && ToggleBoxActive};
`;

interface VillageLayerProps extends ModalProps {
  PopSid?: string | number;
  PopTitle?: string;
  typeApply?: string;
}

const StepImage = styled.img<{ active?: boolean }>`
  width: auto;
  height: 48px;
  margin: 0 auto;
  box-sizing: content-box;
  object-fit: contain;
  padding: 32px 0 68px;
  display: none;
  ${({ active }) => {
    if (active) {
      return `
        display: block;
      `;
    }
    return ``;
  }}
`;
const PopContent = styled.div`
  padding-bottom: 32px;
  .m-main {
    width: 100%;
    max-width: 576px;
    margin: 0 auto;
  }
`;

const VillageLayerPopup: FC<VillageLayerProps> = ({
  onRequestClose,
  location,
  ...props
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [step1Data, setStep1Data] = useState<VillageStep1Data>();
  const [step2Data, setStep2Data] = useState<VillageStep2Data>();

  const currentParent = (data) => {
    setCurrentStep(1);
  };

  return (
    <Modal onRequestClose={onRequestClose} maxWidth="880px" {...props}>
      <PopCcontainer>
        <PopupTitle>
          <h2>
            <Tit
              size="s3"
              color="sky"
              dangerouslySetInnerHTML={{ __html: props.PopTitle }}
            />
          </h2>
          <BtnClose onClick={() => onRequestClose()}>
            <span className="a11y">닫기</span>
          </BtnClose>
        </PopupTitle>
        {props.typeApply === `apply` ? (
          <PopContent>
            <div className="m-main">
              <VillageApplyInfo applyData={props.applyData} />
            </div>
          </PopContent>
        ) : (
          <>
            <StepImage src={Step01} active={currentStep === 1} alt="Step1" />
            <StepImage src={Step02} active={currentStep === 2} alt="Step2" />
            <PopContent>
              <div className="m-main">
                <ToggleBox active={currentStep === 1}>
                  <StepBody>
                    <VillageStep1
                      onSubmit={(data) => {
                        setStep1Data(data);
                        setCurrentStep(2);
                      }}
                    />
                  </StepBody>
                </ToggleBox>
                <ToggleBox active={currentStep === 2}>
                  <StepBody>
                    <VillageStep2
                      step1Data={step1Data}
                      currentParent={currentParent}
                      onSubmit={(data) => {
                        setStep2Data(data);
                        setCurrentStep(3);
                      }}
                    />
                  </StepBody>
                </ToggleBox>
                <ToggleBox active={currentStep === 3}>
                  <StepBody>
                    <VillageStep3 />
                  </StepBody>
                </ToggleBox>
              </div>
            </PopContent>
          </>
        )}
      </PopCcontainer>
    </Modal>
  );
};

export default VillageLayerPopup;
