import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

const FormTextTip = styled.p`
  font-size: 14px;
  line-height: 1.7;
  font-weight: 300;
  letter-spacing: -0.8px;
  position: relative;
  padding-left: 7px;

  &:before {
    content: '*';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 4px;
  }

  ${breakpoint(640)} {
    font-size: 12px;
  }
`;

export default FormTextTip;
