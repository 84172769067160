import IconChecked from '@/assets/img/ico_checkbox@2x.png';
import IconCheckedReadonly from '@/assets/img/ico_checkbox_readonly@2x.png';
import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

export const CommonCheckbox = styled.div`
  display: inline-block;
  font-size: 0;
`;
export const CheckboxObject = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #e5e6e8;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  &::after {
    content: '';
    width: 12px;
    height: 12px;
    background: transparent;
    position: absolute;
    left: 5px;
    top: 5px;
  }
  input[type='checkbox']:checked + & {
    border: 1px solid #1cabe2;
    &::after {
      background: url(${IconChecked}) no-repeat center center;
      background-size: 22px;
    }
  }
  input[type='checkbox'][readonly]:checked + & {
    border: 1px solid #e5e6e8;
    &::after {
      background: url(${IconCheckedReadonly}) no-repeat center center;
      background-size: 22px;
    }
  }
`;
export const CheckboxLabel = styled.label<{ readOnly?: boolean }>`
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 32px;
  line-height: 24px;
  ${({ readOnly }) =>
    readOnly &&
    `
    pointer-events: none;
    color: #b7b8ba;
  `}
`;
export const CheckboxText = styled.span`
  display: inline-block;
  vertical-align: top;
`;
export const CheckboxInput = styled.input.attrs({ type: `checkbox` })`
  display: block;
  z-index: -1;
  height: 1px;
  overflow: hidden;
  color: transparent;
  border: 0;
  padding: 0;
  opacity: 0;
  background: none;
  position: absolute;
  width: 1px;
  margin: 0;
`;

interface InputCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const InputCheckbox = forwardRef<HTMLInputElement, InputCheckboxProps>(
  ({ label, className, style, readOnly, ...props }, ref) => (
    // checkbox는 readOnly속성이 작동하지 않아, CSS로 처리
    <CheckboxLabel className={className} style={style} readOnly={readOnly}>
      <CheckboxInput {...props} type="checkbox" ref={ref} readOnly={readOnly} />
      <CheckboxObject className="obj" />
      <CheckboxText>{label}</CheckboxText>
    </CheckboxLabel>
  ),
);

export default InputCheckbox;
